import styled from '@emotion/styled';

export const CompaniesCarousel = styled.div`
  position: relative;
  padding: 0 28px;

  .alice-carousel__wrapper {
    .alice-carousel__stage-item img {
      display: block;
      height: 60px;
      margin: 0 auto;
    }
  }

  .button-left,
  .button-right {
    position: absolute;
    top: 17px;
  }

  .button-left {
    left: 0;
  }
  .button-right {
    right: 0;
  }
`;
