import { useState } from 'react';
import AliceCarousel from 'react-alice-carousel';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import * as S from './CompaniesCarousel.styles';
import theme from '../../themes/common/theme';

const companyLogos = [
  'almirall.png',
  'bayer.png',
  'boehringer.png',
  'catapult.png',
  'ceva.png',
  'chiesi.png',
  'gsk.png',
  'murata.png',
  'takeda.png',
  'ucb.png',
];

const carouselItems = companyLogos.map((logoName, i) => {
  return <img alt={`${logoName.split('.')[0]} company logo`} key={i} src={`/logos/${logoName}`} />;
});

export const CompaniesCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [itemsInSlide, setItemsInSlide] = useState(5);
  const [responsive] = useState({
    [theme.breakpoints.small]: { items: 5 },
    [theme.breakpoints.tablet]: { items: 3 },
    [500]: { items: 2 },
    0: { items: 1 },
  });

  const slideNext = () => {
    if (currentIndex > carouselItems.length) {
      setCurrentIndex(carouselItems.length);
    } else {
      setCurrentIndex(currentIndex + itemsInSlide);
    }
  };

  const handleOnSlideChanged = e => {
    setItemsInSlide(e.itemsInSlide);
    setCurrentIndex(e.item);
  };

  const slidePrev = () => {
    setCurrentIndex(currentIndex - itemsInSlide);
  };

  return (
    <S.CompaniesCarousel>
      {/* @ts-ignore */}
      <AliceCarousel
        responsive={responsive}
        dotsDisabled={true}
        buttonsDisabled={true}
        items={carouselItems}
        slideToIndex={currentIndex}
        onSlideChanged={handleOnSlideChanged}
      />

      <button className="button-left" onClick={slidePrev}>
        <FaChevronLeft size="28px" />
      </button>

      <button className="button-right" onClick={slideNext}>
        <FaChevronRight size="28px" />
      </button>
    </S.CompaniesCarousel>
  );
};
