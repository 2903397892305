const allowedOrigins = [
  'https://s3.eu-west-1.amazonaws.com/assets.staging.in-part.com',
  'https://s3-eu-west-1.amazonaws.com/assets.staging.in-part.com',
  'https://s3.eu-west-1.amazonaws.com/assets.in-part.com',
  'https://s3.eu-west-1.amazonaws.com/assets.in-part-dev.com',
  'https://s3-eu-west-1.amazonaws.com/assets.in-part.com',
  'https://s3-eu-west-1.amazonaws.com/assets.in-part-dev.com',
  'https://d39u42rrhfeufa.cloudfront.net',
  'https://dstyhlv8lxw5m.cloudfront.net',
  'https://s3-eu-west-1.amazonaws.com/in-part.com/wp-content',
  'https://picsum.photos',
];

// See https://github.com/h2non/imaginary for supported params
// Check with @tom/@nicolas or see backend-services for currently deployed version
export type Operation = {
  name:
    | 'crop'
    | 'smartcrop'
    | 'resize'
    | 'enlarge'
    | 'extract'
    | 'zoom'
    | 'thumbnail'
    | 'fit'
    | 'rotate'
    | 'flip'
    | 'flop'
    | 'convert'
    | 'blur';
  params: {
    [key: string]: any;
  };
};

/**
 * Given a url to an image and an operation, returns a formatted
 * URL for the h2non/imaginary image processing service.
 */
export function imgPath(url: string, operation: Operation, config: { s3BucketUrlAssets: string }): string {
  // The imaginary service doesn't support GIFs currently; bypass...
  // Ignore vector graphics
  if (url.match(/\.(gif|svg)$/) !== null) {
    console.warn('Attempt to resize unsupported image format: ' + url);
    return url;
  }

  let completeUrl = url;

  // Some entities are saved with just the path as the image url
  // e.g. organisations. In these cases we need to generate
  // the full image url.
  if (url.match(/(http(s?)):\/\//i)) {
    completeUrl = url;
  } else if (url.startsWith('assets')) {
    completeUrl = `https://s3-eu-west-1.amazonaws.com/${url}`;
  } else {
    completeUrl = `${config.s3BucketUrlAssets}${encodeURIComponent(url.replace(/^\//, ''))}`;
  }

  const isDev = process.env.NODE_ENV !== 'production' || process.env.NEXT_PUBLIC_STAGE === 'local';

  // Only allowed origins
  // NOTE: any origin allowed in dev mode
  const allowed = allowedOrigins.some(origin => completeUrl.startsWith(origin));
  if (allowed === false) {
    console.warn(
      `Attempt to resize image from unsupported origin: ${completeUrl}\nAllowed origins:\n${allowedOrigins
        .map(o => `- ${o}`)
        .join('\n')}`,
    );
    if (isDev === false) {
      return completeUrl;
    }
  }

  const transformHost = isDev ? 'http://localhost:9000' : 'https://img.in-part.com';

  // Generate transform
  return `${transformHost}/${operation.name}?${new URLSearchParams({
    stripmeta: 'true',
    noprofile: 'true',
    quality: '95',
    url: completeUrl,
    ...operation.params,
  }).toString()}`;
}
