import Link from 'next/link';
import Truncate from 'react-truncate';
import { format, addWeeks, getTime, parseISO } from 'date-fns';
import { FaSearch, FaSearchPlus } from 'react-icons/fa';
import { appImgPath } from '../../util/appImgPath';
import * as S from './CampaignList.styles';

type CampaignListProps = {
  campaigns: Array<{
    shortId: string;
    type: 'ico' | 'rfp';
    title: string;
    closingDate: string;
    details: {
      headerImage: {
        url: string;
      };
    };
  }>;
};

export const CampaignList: React.ElementType<CampaignListProps> = ({ campaigns }) => {
  return (
    <S.CardsContainer>
      {campaigns.map(discoverCall => {
        const discoverCallId = discoverCall.shortId;
        const closed = getTime(new Date()) > getTime(parseISO(discoverCall.closingDate));

        const colorFn = theme => {
          const nowPlusWeek = addWeeks(new Date(), 1);
          const underWeek = getTime(parseISO(discoverCall.closingDate)) < getTime(nowPlusWeek);

          const color = underWeek
            ? closed
              ? theme.colors.listGrey
              : theme.colors.dangerRed
            : theme.colors.successGreen;
          return color;
        };

        return (
          <S.DiscoverCard key={discoverCall.shortId} colorFn={colorFn}>
            <Link key={discoverCallId} href={`/discovercalls/${discoverCallId}`}>
              <img
                src={appImgPath(discoverCall.details.headerImage.url, {
                  name: 'thumbnail',
                  params: {
                    width: 400,
                    quality: 70,
                  },
                })}
              />

              <div className="content">
                <h3>
                  {discoverCall.type === 'rfp' ? <FaSearchPlus size="16px" /> : <FaSearch size="16px" />}
                  {discoverCall.type}
                </h3>

                <div>
                  <Truncate lines={5}>
                    <h2>{discoverCall.title}</h2>
                  </Truncate>
                </div>

                <time dateTime={format(parseISO(discoverCall?.closingDate), 'do MMMM yyyy')}>
                  {closed ? 'Closed' : 'Closing date'}: {format(parseISO(discoverCall?.closingDate), 'do MMMM yyyy')}
                </time>
              </div>
            </Link>
          </S.DiscoverCard>
        );
      })}
    </S.CardsContainer>
  );
};
