import styled from '@emotion/styled';

export const CardsContainer = styled.div`
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, 264px);
  grid-gap: 30px;
`;

type DiscoverCardProps = {
  colorFn: (theme) => string;
};

export const DiscoverCard = styled.div<DiscoverCardProps>`
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
  background-color: white;
  border-radius: 10px;
  border: ${({ theme }) => theme.colors.lightGrey} 1px solid;
  transition: box-shadow 100ms linear;

  &:hover {
    box-shadow: ${({ theme }) => theme.shadows.cardBoxShadow};
  }

  a {
    display: block;
    width: 100%;
    height: 100%;
    text-decoration: none;
    text-align: left;

    img {
      width: 100%;
      height: 170px;
      object-fit: cover;
    }

    .content {
      display: flex;
      flex-direction: column;
      height: 200px;
      padding: 16px;

      h3 {
        padding: 0;
        margin: 0;
        max-height: 30px;
        font-size: 16px;
        font-weight: bold;
        overflow: hidden;
        text-transform: uppercase;
        line-height: 1.3;
        margin-bottom: 10px;
        color: ${({ theme }) => theme.colors.successGreen};

        svg {
          color: ${({ theme }) => theme.colors.successGreen};
          margin-right: 5px;
        }
      }

      time {
        color: ${({ theme, colorFn }) => colorFn(theme)};
        font-weight: bold;
        margin-top: auto;
        font-size: 12px;
      }
    }
  }
`;
