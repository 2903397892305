import { isArray } from 'lodash';

/**
 * The NextJS router formats querystring arrays in the following manner:
 *  - Single term: search[]=term -> { "search[]": "term" }
 *  - Multiple terms: search[]=term&search[]=otherterm -> { "search[]": [ "term", "otherterm" ] }
 * This function consistenly turns these into an array
 */
export function normaliseNextRouterQuerystring(queryFromNextRouter: { [key: string]: any }): { [key: string]: any } {
  return Object.entries(queryFromNextRouter).reduce((obj, [key, val]) => {
    if (/\[\]$/.test(key)) {
      const cleanedKey = key.replace(/\[\]$/, '');
      // Next will create an array for us if there are
      // multiple values with the same key
      if (isArray(val)) {
        obj[cleanedKey] = val;
      } else {
        if (obj[cleanedKey]) {
          obj[cleanedKey].push(val);
        } else {
          obj[cleanedKey] = [val];
        }
      }
    } else {
      obj[key] = val;
    }
    return obj;
  }, {});
}
