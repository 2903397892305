import styled from '@emotion/styled';
import { css } from '@emotion/react';

const fullWidthBackground = ({ styles, where = 'after' }: { styles: any; where?: 'before' | 'after' }) => css`
  position: relative;

  &::${where} {
    content: '';
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    top: 0;
    left: 0;
    position: absolute;
    height: 100%;
    z-index: -1;
    ${styles}
  }
`;

export const HomePage = styled.div``;

export const SectionHeader = styled.h3`
  margin: 50px 0 50px 0;
  font-weight: bold;
  font-size: 28px;
  text-align: center;
`;

export const HeroSection = styled.section`
  ${fullWidthBackground({
    where: 'before',
    styles: css`
      background-image: url('/roads-large.jpg');
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    `,
  })}

  ${fullWidthBackground({
    where: 'after',
    styles: css`
      z-index: -1;
      background: linear-gradient(to top, rgba(47, 74, 99, 0.7) 0%, rgba(47, 74, 99, 0.7) 100%);
    `,
  })}

  width: 100%;
  padding: 60px 0;

  h1,
  p,
  a {
    color: #fff;
  }

  h1 {
    line-height: 2.5rem;
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 12px;
    max-width: 400px;
  }

  p {
    font-size: 18px;
    line-height: 1.5rem;
    margin-bottom: 20px;
  }

  @media (min-width: 768px) {
    padding: 100px 0;

    h1 {
      margin-bottom: 22px;
    }

    p {
      margin-bottom: 30px;
    }
  }

  a {
    display: inline-block;
    margin: 0;
    padding: 8px 16px;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    line-height: 1.25;
    border-radius: 6px;
    background-color: #0054a6;
    color: #fff;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const WhyDiscoverSection = styled.section`
  ${({ theme }) =>
    fullWidthBackground({
      styles: css`
        background-color: ${theme.colors.lightGrey};
      `,
    })}

  padding: 32px 0 40px 0;
  margin-bottom: 30px;

  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @media (min-width: 768px) {
      flex-direction: row;
    }

    li {
      padding: 0 1rem 0 2rem;
      margin: 0 0 1rem 0;
      text-indent: -1.1rem;

      &:last-child {
        margin-bottom: 0;
      }

      @media (min-width: 768px) {
        margin: 0;
      }

      svg {
        position: relative;
        color: ${({ theme }) => theme.colors['successGreen']};
        vertical-align: bottom;
        left: -1rem;
        font-size: 1.1rem;
      }
    }
  }
`;

export const CampaignListSection = styled.div`
  margin: 0 auto 40px;
  width: 100%;
`;

export const CardsContainer = styled.div`
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  max-width: 282px;

  a:hover {
    text-decoration: none;
  }

  @media screen and (min-width: 640px) {
    max-width: 564px;
  }

  @media screen and (min-width: 920px) {
    max-width: 846px;
  }

  @media screen and (min-width: 1250px) {
    max-width: 1600px;
  }

  @media screen and (min-width: 1460px) {
    margin-left: -16px;
  }
`;

export const CarouselSection = styled.div``;
