import Link from 'next/link';
import Head from 'next/head';
import { GetStaticProps, InferGetStaticPropsType } from 'next';
import { FaCheck } from 'react-icons/fa';
import { fetchCampaigns } from '@/hooks';
import { CampaignList, CompaniesCarousel, S } from '@/components/HomePage';
import { ENV } from '@/constants';

const ACTIVE_CAMPAIGN_LIMIT = 30;
const CLOSED_CAMPAIGN_LIMIT = 5;

type HomePageProps = InferGetStaticPropsType<typeof getStaticProps>;

const HomePage: React.ElementType<HomePageProps> = ({ activeCampaigns, previousCampaigns }) => {
  return (
    <S.HomePage>
      <Head>
        <title>{ENV.NEXT_PUBLIC_ORGANISATION_NAME}</title>
      </Head>

      <S.HeroSection>
        <h1>Engage with world leading R&amp;D teams</h1>

        <p>View the latest campaigns seeking academic partners and funding new research</p>

        <a
          href={`${ENV.NEXT_PUBLIC_COMPANIES_APP_URL}/register/academic?${new URLSearchParams({
            utm_source: 'discover-homepage',
            utm_medium: 'discover-public',
            utm_content: 'updates-button',
          }).toString()}`}
        >
          Register here for updates
        </a>
      </S.HeroSection>

      <S.WhyDiscoverSection>
        <ul>
          <li>
            <FaCheck />
            Receive the latest calls from industry seeking solutions and expertise from academia
          </li>

          <li>
            <FaCheck />
            Get introductions to companies, collaborate on projects and commercialise technology
          </li>

          <li>
            <FaCheck />
            Submit research projects, academic researchers, technologies, spinout companies, or centres of excellence
          </li>
        </ul>
      </S.WhyDiscoverSection>

      <S.CampaignListSection>
        <S.SectionHeader>Active campaigns</S.SectionHeader>

        {activeCampaigns.length === 0 ? (
          <p style={{ textAlign: 'center' }}>
            New campaigns coming soon.{' '}
            <Link href="/register/academic" style={{ fontWeight: 'bold' }}>
              Register
            </Link>{' '}
            to stay updated.
          </p>
        ) : (
          <CampaignList campaigns={activeCampaigns} />
        )}
      </S.CampaignListSection>

      {previousCampaigns.length > 0 && (
        <S.CampaignListSection>
          <S.SectionHeader>Previous campaigns</S.SectionHeader>

          <CampaignList campaigns={previousCampaigns} />
        </S.CampaignListSection>
      )}

      <S.CarouselSection>
        <S.SectionHeader>Trusted by</S.SectionHeader>

        <CompaniesCarousel />
      </S.CarouselSection>
    </S.HomePage>
  );
};

type CampaignList = Awaited<ReturnType<typeof fetchCampaigns>>['data']['data']['results'];

export const getStaticProps: GetStaticProps<{
  activeCampaigns: CampaignList;
  previousCampaigns: CampaignList;
}> = async () => {
  const currentDate = new Date();

  const [activeCampaigns, previousCampaigns] = await Promise.allSettled([
    fetchCampaigns({ closingAfter: currentDate, limit: ACTIVE_CAMPAIGN_LIMIT }),
    fetchCampaigns({ closingBefore: currentDate, limit: CLOSED_CAMPAIGN_LIMIT, order: 'desc' }),
  ]);

  return {
    props: {
      activeCampaigns: activeCampaigns.status === 'fulfilled' ? activeCampaigns.value.data.data.results : [],
      previousCampaigns: previousCampaigns.status === 'fulfilled' ? previousCampaigns.value.data.data.results : [],
    },
    revalidate: 60, // seconds
  };
};

export default HomePage;
